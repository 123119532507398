.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .spinner {
    position: fixed; /* Position the spinner relative to the viewport */
    top: 50%; /* Center vertically */
    left: 60%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Offset by 50% to truly center */
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 64px; /* Size of the spinner */
    height: 64px; /* Size of the spinner */
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s ease infinite;
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .loading-text {
    margin-top: 16px;
    font-size: 18px;
    color: #333;
    top: 80%; /* Center vertically */
    left: 60%; /* Center horizontally */
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  